import * as React from 'react';
import * as styles from './shop.module.css';

import {
	Box,
	Button,
	HStack,
	Heading,
	Link,
	Select,
	SimpleGrid,
	Text,
	VStack,
} from '@chakra-ui/react';
import { FunctionComponent, useState } from 'react';

import Layout from '../components/Base/Layout';
import { Logo } from '../components/Base/Logo';
import { PRODUCTS_PER_PAGE } from '../constants/shopConstants';
import { PageProps } from 'gatsby';
import Pagination from '../components/Shop/Pagination';
import { ProductBreadcrumb } from '../components/Shop/ProductBreadcrumbs';
import { SEO } from '../components/Base/Seo';
import { SingleProductCard } from '../components/Product/SingleProductCard';
import { fetchAllProducts } from '../components/hooks/queries';
import { fetchAllVariableProducts } from '../components/hooks/fetchAllVariableProducts';
import { useFilteredProducts } from '../utils/filteredProducts';

const Outlet: FunctionComponent<PageProps> = ({ location }) => {

	const allProducts = fetchAllVariableProducts();
	const filteredProducts = useFilteredProducts(allProducts);

	//PAGINATION
	const [currentPage, setCurrentPage] = useState(1);

	const totalNumberOfPages = Math.ceil(
		filteredProducts.length / PRODUCTS_PER_PAGE
	);

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};

	const handleNextPage = () => {
		if (currentPage < totalNumberOfPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const goToPage = (number: number) => {
		setCurrentPage(number);
		window.scrollTo(0, 0);
	};

	const shouldRenderPagination = filteredProducts.length > PRODUCTS_PER_PAGE;

	const isOutletEmpty = allProducts.length == 0 ? true : false;

	return (
		<>
			<Layout>
				<Box bg='#FAFAFA'>
					<Box
						maxW='1280px'
						margin='0px auto'
						pt={{ base: '116px', lg: '8px' }}
						px={{ base: '0px', xl: '20px' }}
						pb="56px"
					>
						<Box
							bg='white'
							display='flex'
							padding='24px 20px 24px 20px '>
							<ProductBreadcrumb
								home='Home'
								outlet='Outlet'
							/>
						</Box>

						<HStack
							bg='#FAFAFA'
							flexFlow={{ base: 'column', lg: 'row' }}
							gap='40px'
							alignItems='center'
							p='40px 20px'>
							<Box w={{ base: '100%', lg: '50%' }}>
								<Box>
									<Box
										bg='#B97230'
										width='24px'
										height='2px'></Box>
									<Text
										textTransform='uppercase'
										color='#D28C49'
										fontSize='20px'
										fontWeight='700'
										pt='40px'>
										EXCLUSIVE OUTLET DEALS
									</Text>
									<Heading
										as='h1'
										fontSize='36px'
										lineHeight='54px'
										fontWeight='400'
										color='gray.900'>
										For Medical & Aesthetic Professionals
									</Heading>
								</Box>
							</Box>
							<Box w={{ base: '100%', lg: '50%' }}>
								<Text
									color='#404040'
									fontSize='16px'
									lineHeight='21px'
									fontWeight='400'
									pb='8px'>
									{' '}
									Welcome to our specialized outlet, your hub for essential
									products offered at significantly reduced prices. Benefit from
									these unique discounts tailored for innovation-driven
									professionals:
								</Text>
								<Text
									fontSize='14px'
									color='#404040'
									fontWeight='400'>
									<span style={{ color: '#D28C49', fontWeight: '600' }}>
										Near Expiry Deals
									</span>
									: Browse products nearing their expiration date offered at
									reduced prices. For full transparency, each item’s expiration
									date is clearly indicated.
								</Text>
							</Box>
						</HStack>

						{isOutletEmpty ? (
							<></>
						) : (
							<HStack
								bg='white'
								p='20px 8px'
								borderBottom='1px solid #D9D9D9'
								justifyContent='space-between'>
								<HStack
									flexFlow={{ base: 'column', sm: 'row' }}
									alignItems='flex-start'>
									<Heading
										as='h3'
										color='gray.900'
										fontSize='20px'
										fontWeight='700'>
										Outlet deals
									</Heading>
									<Text
										color='beige.300'
										fontSize='14px'
										fontWeight='600'>
										(15 products)
									</Text>
								</HStack>

								<HStack
									border='1px solid #c1c5c7'
									borderRadius='6px'
									h='40px'
									pl={2}
									pt={2}
									pb={2}>
									<Select
										className={styles.sort}
										size='sm'
										aria-label='Sort by'
										rounded='md'
										defaultValue={'DEFAULT'}
										variant='unstyled'>
										<option value='priceDESC'>Sort by: Price Ascend</option>
										<option value='priceASC'>Sort by: Price Descend</option>
										<option value='a-z'>Sort by: Name A-Z</option>
										<option value='z-a'>Sort by: Name Z-A</option>
									</Select>
								</HStack>
							</HStack>
						)}

						<Box bg='#FAFAFA'>
							<Box
								pt='8px'
								px={{ base: "16px", lg: "0px" }}
								margin='0px auto'>
								<SimpleGrid
									columns={{ base: 1, sm: 2, md: 3, lg: 5 }}
									gap='8px'>
									{filteredProducts
										.slice(
											(currentPage - 1) * PRODUCTS_PER_PAGE,
											currentPage * PRODUCTS_PER_PAGE
										)
										.map((item: any) => (
											<SingleProductCard
												qtyInputOn={false}
												key={item.id}
												props={item}
												outlet
												margin={'0 auto'}
												width={'100%'}
											/>
										))}
								</SimpleGrid>
							</Box>
							{shouldRenderPagination && (
								<Pagination
									numberOfPages={totalNumberOfPages}
									currentPage={currentPage}
									prevPageHandler={handlePrevPage}
									nextPageHandler={handleNextPage}
									goToPage={goToPage}
									baseUrl={'outlet'}
								/>
							)}
						</Box>
					</Box>
					<Box
						bg='#DCA672'
						py='40px'
						px='20px'>
						<VStack
							maxW='680px'
							margin='0px auto'
							color='white'
							textAlign={isOutletEmpty ? 'left' : 'center'}
							gap='16px'>
							<Heading
								fontSize='30px'
								fontWeight='700'>
								{isOutletEmpty
									? "Outlet Update: We're Restocking!"
									: 'Unlock All Offers:'}
							</Heading>
							<Text
								fontSize='16px'
								fontWeight='400'>
								{isOutletEmpty
									? "Our outlet, featuring soon-to-expire products at incredible discounts, is currently being refreshed. These deals are ideal for immediate use, offering the same high quality at lower prices. Don't miss out! Keep an eye on this space for updates or explore our full range of products in the main store."
									: `Ensure you're logged in to view the complete range of our promotions`}
							</Text>
							<Link
								aria-label='sign in'
								href={isOutletEmpty ? '/shop' : '/sign-in'}>
								<Button
									variant='solidWhite'
									width={isOutletEmpty ? '290px' : '180px'}>
									{isOutletEmpty ? 'Continue shopping' : 'LOG IN'}
								</Button>
							</Link>
						</VStack>
					</Box>

				</Box>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Outlet Product List | DoctorMedica'
		description='See our discounted product selection and browse for your favourite supplies quickly and easily.'
	/>
);

export default Outlet;
